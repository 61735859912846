<template>
    <v-container class="mb-10 mt-3 fade">
        <v-row class="primary-font">
            <v-col cols="12" md="6" class="py-3 f14 d-flex flex-column justify-center">
                  <Contact />
            </v-col>
            <v-col cols="12" md="6" class="d-flex flex-column align-center justify-center">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3859.3522922671655!2d121.09570771476044!3d14.692658789743918!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397b70bffffffff%3A0x6f259137bef99dac!2sCivil%20Service%20Commission%20Central%20Office!5e0!3m2!1sen!2sph!4v1677043745906!5m2!1sen!2sph" width="500" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="mb-5"></iframe>
                <v-list dense>
                    <v-list-item v-for="(item, i) in contacts" :key="i">
                        <v-list-item-icon>
                            <v-icon color="l-primary">
                                {{ item.icon }}
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle>
                            {{ item.text }}
                        </v-list-item-subtitle>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Contact from '@/components/landing/forms/Contact.vue';

export default {
    components: {
        Contact
    },
    data: () => ({
        contacts: [
            {
                icon: 'mdi-map-marker',
                text: 'IBP Road, Constitution Hills 1126 Quezon City, Philippines'
            },
            {
                icon: 'mdi-phone',
                text: '(02) 8931-4182, 8931-7935 loc 301 to 305, 361 to 362'
            },
            {
                icon: 'mdi-phone',
                text: '(02) 8931-8019; 8931-4143'
            },
            {
                icon: 'mdi-email',
                text: 'csi@csc.gov.ph'
            },
            {
                icon: 'mdi-web',
                text: 'http://csi.csc.gov.ph'
            },
        ]
    })
}
</script>